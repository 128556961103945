.conclusionCard__container {
  width: 15rem;
  height: 18rem;
  display: grid;

  grid-template-rows: 1fr 3fr;
}
.conclusionCard__heading{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #414549;
    font-size: 2.3rem;
    margin-top: 1rem;
    margin: auto;
}
.conclusionCard__content {
 display: grid;
 grid-template-rows: 2fr 3fr;
  background: #ffffff;
  box-shadow: 2px 2px 60px 4px rgba(203, 203, 203, 0.2);
  border-radius: 20px;

}

.conclusionCard__icon{
    margin-top: 1rem;
    background-image: url('../../assets/bow.svg');
  
 
    background-position: center;
    background-repeat:no-repeat;
    background-size: contain;
}
.conclusionCard__content span{
  margin-top: 2rem;
}
.conclusionCard__content p{
    font-family: 'Inter', sans-serif;
    color: #414549;
    font-size: 1rem;
    font-weight: 400;

    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    justify-content: center;
}
