.conclusion__container {
  height: 100vh;
  padding: 2rem;
  box-sizing: border-box;
  background-color: #fcfcfc;
}
.conclusion__logo {
  height: 50px;
  width: auto;
  background-image: url("../../assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.conclusion__content {
  margin-top: 5rem;
  height: 50vh;
}
.conclusion__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #414549;

  font-size: 2.5rem;
  /* background-color: ; */
}
.conclusion__innerContent {
  align-items: center;
  width: fit-content;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #414549;
  font-size: 1.3rem;
  font-weight: 400;
  margin: auto;
  margin-top: 2rem;
}
.conclusion__cards {
  padding: 2rem;

  display: grid;

  justify-content: end;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.conclusion__items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.conclusion__loader {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.conclusion__button{
  display: flex;
  justify-content: center;
}
.conclusion__button button{
  margin-top: 2rem;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 400px;
  height:70px;
  border: none;
  background-color: #4395f5;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .conclusion__cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .conclusion__text {
    font-size: 2rem;
  }
  .conclusion__innerContent {
    flex-wrap: nowrap;
    font-size: 1rem;
    width: fit-content;
  }
  .conclusion__cards {
    grid-template-columns: 1fr;
  }
  .conclusion__button button{
    font-size: 1.3rem;
    width: 200px;
    height: 50px;
    margin-bottom: 3rem;

  }
}
