.payCard__container {
  width: 300px;
  height: 400px;
  position: relative;
  // background-color: aqua;
  background: #ffffff;
  box-shadow: 2px 2px 60px 4px rgba(203, 203, 203, 0.1);
  border-radius: 20px;
}
.dots {
  background-image: url("../../assets/Dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 70px;
  position: absolute;
  left: 230px;
}
.ribbon1 {
  background-image: url("../../assets/Top.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 60px;
  position: absolute;
}
.ribbon2 {
  background-image: url("../../assets/Bottom.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.crown{
  background-image: url('../../assets/image/crown.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2.8rem;
  right: 4.5rem;

}
.Premium__plan {
  // background-image: url("../../assets/Premium.svg");
  background-repeat: no-repeat;
  background-size: contain;

  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  margin-top: 3.5rem;
}
.payCard__content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  top: 3rem;
  width: 80%;
  margin: auto;
}
.payCard__content p {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  font-size: 1.1rem;

  color: #414549;
  margin: 0;
}
a.foo, a.foo:link, a.foo:visited, a.foo:hover, a.foo:focus, a.foo:active{
  color:white;
}
.payCard__buynow {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}
.payCard__buynow button {
  font-size: 1.1rem;
  border-radius: 10px;
  width: 180px;
  font-weight: 300;
  height: 40px;
  border: none;
  background-color: #4395f5;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payCard__buynow a{
  text-decoration: none;
  color: white;
}
.payCard__check {
  background-image: url("../../assets/check.svg");
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}
